import React, { useState } from 'react';
import {
    Navbar,
    UncontrolledDropdown,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
    Modal,
    ModalHeader,
    ModalBody,
    Button,
    ModalFooter,
    Spinner,
} from 'reactstrap';

import { Power, User } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { authLogout } from 'ducks/auth';
import { clearDataUser } from 'ducks/user';

import { requestOrder, requestConfirmVoucher } from 'services/orders';
import moment from 'moment';

const errorsList = [
    {
        text: 'There are validation errors: order already used',
        translate: 'Este voucher já foi utilizado!',
    },
    {
        text: 'There are validation errors: order is canceled',
        translate: 'Este voucher foi cancelado!',
    },
    {
        text: 'There are validation errors: order is expired',
        translate: 'Este voucher expirou',
    },
    {
        text: 'There are validation errors: order is not in date',
        translate: 'Ainda não deu o horário comprado',
    },
    {
        text: 'Order not found',
        translate: 'Voucher não encontrado',
    },
];

const Navheader = (props) => {
    const dispatch = useDispatch();
    const { name, group } = useSelector((store) => store.user);

    const groupsAvaliable = {
        admin: 'Administrador',
        franchisee: 'Franqueado',
        store: `Estabelecimento`,
    };

    const [code, setCode] = useState('');
    const [order, setOrder] = useState({});

    const [modal, setModal] = useState(false);
    const [loading, setLoading] = useState(false);

    const [successConfirmVoucher, setSuccessConfirmVoucher] = useState(false);
    const [errorConfirmVoucher, setErrorConfirmVoucher] = useState('');

    const toggle = () => setModal(!modal);

    const requestConfirmUse = () => {
        setLoading(true);
        requestConfirmVoucher(code)
            .then((res) => {
                setSuccessConfirmVoucher('Voucher confirmado!');
                setErrorConfirmVoucher('');
            })
            .catch((error) => {
                const errorFiltered = errorsList.find(
                    (el) => el.text === error?.data?.message
                );
                if (errorFiltered) {
                    setErrorConfirmVoucher(errorFiltered.translate);
                } else {
                    setErrorConfirmVoucher(
                        'Ocorreu um erro ao validar o voucher!'
                    );
                }
            })
            .finally(() => {
                setLoading(false);
                setCode('');
                setOrder({});
            });
    };

    const submitSearch = () => {
        if (code !== '') {
            setLoading(true);

            requestOrder(code)
                .then((res) => {
                    setOrder(res);

                    setSuccessConfirmVoucher(false);
                    setErrorConfirmVoucher('');

                    let message = '';
                    if (res.canceled) {
                        message = 'Este voucher foi cancelado!';
                    } else if (res.used) {
                        message = 'Este voucher já foi utilizado!';
                    } else if (res.expired) {
                        message = 'Este voucher expirou!';
                    }

                    if (message) {
                        setCode('');
                        setErrorConfirmVoucher(message);
                    }
                })
                .catch((error) => {
                    const errorFind = errorsList.find(
                        (el) => el.text === error.data.message
                    );

                    setCode('');

                    if (errorFind) {
                        setErrorConfirmVoucher(errorFind.translate);
                    } else {
                        setErrorConfirmVoucher(
                            'Ocorreu um erro ao procurar este voucher!'
                        );
                    }

                    // if (error.data.canceled) {
                    //     setErrorConfirmVoucher('Este voucher foi cancelado!');
                    // } else if (error.data.used) {
                    //     setErrorConfirmVoucher(
                    //         'Este voucher já foi utilizado!'
                    //     );
                    // } else if (error.data.expired) {
                    //     setErrorConfirmVoucher('Este voucher expirou!');
                    // } else {
                    //     setErrorConfirmVoucher('Este voucher expirou!');
                    // }
                })
                .finally(() => {
                    setLoading(false);
                    setModal(true);
                });
        }
    };

    const renderBody = () => {
        if (loading) {
            return (
                <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                    <Spinner color="primary" />
                </div>
            );
        } else if (successConfirmVoucher) {
            return <span>Voucher confirmado</span>;
        }

        if (errorConfirmVoucher) {
            return <span>{errorConfirmVoucher}</span>;
        }

        const types = {
            product: 'Produto',
            service: 'Serviço',
            combo: 'Combo',
        };

        return (
            <>
                <div>
                    {group === 'admin' && (
                        <span className="d-block mb-1">Região:</span>
                    )}
                    {group !== 'store' && (
                        <span className="d-block mb-1">Estabelecimento:</span>
                    )}
                    <span className="d-block mb-1">Nome:</span>
                    <span className="d-block mb-1">Tipo de voucher:</span>
                    <span className="d-block mb-1">Dia:</span>
                    <span className="d-block mb-1">Horário:</span>
                </div>
                <div className="ml-2">
                    {group === 'admin' && (
                        <span className="d-block mb-1">
                            <strong>{order?.store?.region?.name}</strong>
                        </span>
                    )}
                    {group !== 'store' && (
                        <span className="d-block mb-1">
                            <strong>{order?.store?.company_name}</strong>
                        </span>
                    )}
                    <span className="d-block mb-1">
                        <strong>{`${order?.customer?.first_name} ${order?.customer?.last_name}`}</strong>
                    </span>
                    <span className="d-block mb-1">
                        <strong>{types[order?.type]}</strong>
                    </span>
                    <span className="d-block mb-1">
                        <strong>
                            {moment(order.booking_date)
                                .utcOffset(0)
                                .format('DD/MM/YYYY')}
                        </strong>
                    </span>
                    <span className="d-block mb-1">
                        <strong>
                            {moment(order.booking_date)
                                .utcOffset(0)
                                .format('HH:mm')}
                        </strong>
                    </span>
                </div>
            </>
        );
    };

    return (
        <div>
            <div className="content-overlay" />
            <div className="header-navbar-shadow" />
            <Navbar className="header-navbar navbar-expand-lg navbar navbar-with-menu navbar-shadow navbar-light floating-nav navbar">
                <div className="navbar-wrapper d-flex justify-content-between align-items-center">
                    <div>
                        <div className="input-group ml-1">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Insira o código do voucher"
                                aria-label="Recipient's username"
                                aria-describedby="basic-addon2"
                                onChange={(event) =>
                                    setCode(event.target.value)
                                }
                                value={code}
                                disabled={loading}
                            />
                            <div className="input-group-append">
                                <button
                                    className="btn btn-success btn-green"
                                    type="button"
                                    disabled={loading}
                                    onClick={submitSearch}
                                >
                                    {loading ? (
                                        <Spinner size="sm" />
                                    ) : (
                                        'Validar'
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="navbar-container w-25 content">
                        <ul className="nav navbar-nav navbar-nav-user float-right">
                            <UncontrolledDropdown
                                tag="li"
                                className="dropdown-user nav-item"
                            >
                                <DropdownToggle
                                    tag="a"
                                    className="nav-link dropdown-user-link"
                                >
                                    <div className="user-nav d-sm-flex d-none">
                                        <span className="user-name text-bold-600">
                                            {name}
                                        </span>
                                        <span className="user-status">
                                            {groupsAvaliable[group]}
                                        </span>
                                    </div>
                                    <span
                                        data-tour="user"
                                        className="customizer-user-icon"
                                    >
                                        <User size={20} />
                                    </span>
                                </DropdownToggle>
                                <DropdownMenu right>
                                    <DropdownItem
                                        tag="a"
                                        href="/login"
                                        onClick={async (e) => {
                                            e.preventDefault();
                                            window.localStorage.removeItem(
                                                'token'
                                            );
                                            await dispatch(authLogout());
                                            await dispatch(clearDataUser());
                                        }}
                                    >
                                        <Power size={14} className="mr-50" />
                                        <span className="align-middle">
                                            Sair
                                        </span>
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </ul>
                    </div>
                </div>
            </Navbar>

            <Modal isOpen={modal} toggle={toggle} centered={true}>
                <ModalHeader toggle={toggle}>Validar voucher</ModalHeader>
                <ModalBody className="d-flex">{renderBody()}</ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={requestConfirmUse}
                        disabled={
                            errorConfirmVoucher !== '' || successConfirmVoucher
                        }
                    >
                        Confirmar uso
                    </Button>{' '}
                    <Button color="secondary" onClick={toggle}>
                        Cancelar
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default Navheader;
